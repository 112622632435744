import actionTypes from '../actionTypes'

export default {
    setBluetoothStatus: (status) => ({
        type: actionTypes.SET_BT_STATUS,
        status
    }),
    SetBluetoothDevice: (device) => ({
        type: actionTypes.SET_BT_DEVICE,
        bluetoothDevice: device
    }),
  setUsbStatus: (status) => ({
    type: actionTypes.SET_USB_STATUS,
    status
  }),
  SetUsbDevice: (device) => ({
    type: actionTypes.SET_USB_DEVICE,
    usbDevice: device
  })
}
