import React, { useCallback } from "react";
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import 'regenerator-runtime/runtime';

async function onLoginSuccess(res) {
  // check if user profile valid, can later be used to grab permissions if needed
  let verifyResult = await fetch(`https://www.googleapis.com/admin/directory/v1/users/${res.googleId}?projection=full&viewType=domain_public`, {
                            headers: { 'Authorization': `Bearer ${res.accessToken}`}
                          });
  if (verifyResult.ok) {
    localStorage.setItem("token",res.accessToken);
    localStorage.setItem("googleId",res.googleId);
    window.location.reload(false);
  }
  else {
    console.log("Could not verify google profile: " + verifyResult);
  }
}

const onLoginFailure = (res) => {
  console.log('Login failed: ', res);
  localStorage.clear();
}

const onLogoutSuccess = () => {
  localStorage.clear();
  window.location.reload(false);
};

export { onLoginSuccess, onLoginFailure, onLogoutSuccess };
